import React from "react"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"

import AudioPlayer from "../../../../../components/audio-player"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Note from "../../../../../components/note"
import PaperStack from "../../../../../components/paper-stack"
import Poem from "../../../../../components/poem"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"
import TaskSidebar from "../../../../../components/task-sidebar"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "hoelderlins-reisen" })

  return (
    <LayoutCourse
      footer={
        <CourseFooterNext
          label="Zur Zusammenfassung"
          to="/kurse/hoelderlins-reisen/02-reiseerinnerung/zusammenfassung"
        />
      }
      navigation={navigation}
    >
      <Seo title="Dein eigener Gedichtvortrag" />
      <Stack>
        <Heading as="h2" level={2}>
          Dein eigener Gedichtvortrag
        </Heading>
        <Paragraph>
          Nun bist du selbst an der Reihe! Versuche, das Gedicht laut zu lesen.
          Achte dabei auf die richtige Betonung und auf den Rhythmus der Wörter.
          Am Ende jeder Zeile machst du eine kurze Pause, damit die
          Zuhörer*innen mitbekommen, dass hier eine neue Zeile beginnt.
        </Paragraph>
        <Paragraph>
          Zur Vorbereitung kannst du dir auch anhören, wie andere das Gedicht
          vorgetragen haben.
        </Paragraph>
        <Grid columns={[1, 2]}>
          <AudioPlayer
            title={
              <Text sans>
                Christian Reiner liest{" "}
                <Text bold as="span">
                  ›Heidelberg‹
                </Text>
              </Text>
            }
            src={`kurse/heidelberg`}
          />
          <AudioPlayer
            title={
              <Text sans>
                Robert Arnold liest{" "}
                <Text bold as="span">
                  ›Heidelberg‹
                </Text>
              </Text>
            }
            src={`kurse/heidelberg-arnold`}
          />
        </Grid>
        <TaskSidebar
          main={
            <PaperStack>
              <Poem size={[3, 3, 4]}>
                <p>Lange lieb ich dich schon, möchte dich, mir zur Lust,</p>
                <p>Mutter nennen und dir schenken ein kunstlos Lied,</p>
                <p>Du der Vaterlandsstädte</p>
                <p>Ländlichschönste, so viel ich sah.</p>
                <br />
                <p>Wie der Vogel des Walds über die Gipfel fliegt,</p>
                <p>Schwingt sich über den Strom, wo er vorbei dir glänzt</p>
                <p>Leicht und kräftig die Brücke</p>
                <p>Die von Wagen und Menschen tönt.</p>
                <br />
                <p>Wie von Göttern gesandt, fesselt ein Zauber einst</p>
                <p>Auf der Brüke mich an, da ich vorüber gieng</p>
                <p>Und herein in die Berge</p>
                <p>Mir die reizende Ferne schien,</p>
                <br />
                <p>Und der Jüngling, der Strom fort in die Ebne zog</p>
                <p>Traurigfroh, wie das Herz, wenn es, sich selbst zu schön</p>
                <p>Liebend unterzugehen</p>
                <p>In die Fluten der Zeit sich wirft.</p>
                <p>Quellen hattest du ihm, hattest dem Flüchtigen</p>
                <p>Kühle Schatten geschenkt, und die Gestade sahn</p>
                <p>All ihm nach, und es bebte</p>
                <p>Aus den Wellen ihr lieblich Bild.</p>
                <br />
                <p>Aber schwer in das Tal hieng die gigantische,</p>
                <p>Schicksalskundige Burg nieder bis auf den Grund</p>
                <p>Von den Wettern zerrissen;</p>
                <p>Doch die ewige Sonne goß</p>
                <br />
                <p>Ihr verjüngendes Licht über das alternde</p>
                <p>Riesenbild, und umher grünte lebendiger</p>
                <p>Efeu; freundliche Wälder</p>
                <p>Rauschten über die Burg herab.</p>
                <br />
                <p>Sträuche blühten herab, bis wo im heitern Thal,</p>
                <p>An den Hügel gelehnt, oder dem Ufer hold,</p>
                <p>Deine fröhlichen Gassen</p>
                <p>Unter duftenden Gärten ruhn.</p>
              </Poem>
            </PaperStack>
          }
          sidebar={
            <Note variant="task">
              Lies das Gedicht laut vor. Achte dabei auf die richtige Betonung
              und auf den Rhythmus der Wörter.
            </Note>
          }
        />
      </Stack>
    </LayoutCourse>
  )
}

export default Page
